$mainTextColor: #333333;
$mainBackgroundColor: #ffffff;
$mainBackgroundSecondaryColor: $mainTextColor;
$disabledColor: #dddddd;

/* Primary and Secondary accent color for your app */
$primaryColor: #75AAAD;
$primaryTextColor: #ffffff;
$secondaryColor: #BED7E3;
$tertiaryColor: #BED7E3;
/* =============================================== */

$infoTextColor: #0072bb;
$infoBackgroundColor: #07CCD6;

$successTextColor: #006e57;
$successBackgroundColor: #a9e2da;

$warningTextColor: #a99700;
$warningBackgroundColor: #f6f2bd;

$errorTextColor: #ca1829;
$errorBackgroundColor: #ffccd3;
